/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-aid-myths-and-facts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aid-myths-and-facts",
    "aria-label": "hearing aid myths and facts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aid myths and facts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several myths and misconceptions surrounding ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " that may discourage people from trying them early. Addressing these myths can help individuals make informed decisions about their hearing health. Here are some common myths:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-are-only-for-the-elderly",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-are-only-for-the-elderly",
    "aria-label": "hearing aids are only for the elderly permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids are only for the elderly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can affect people of all ages. While it is more prevalent in older adults, younger individuals may also experience hearing issues due to various factors such as exposure to loud noises, genetics, or medical conditions."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-are-big-uncomfortable-and-noticeable",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-are-big-uncomfortable-and-noticeable",
    "aria-label": "hearing aids are big uncomfortable and noticeable permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids are big, uncomfortable, and noticeable."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids come in various styles and sizes, many of which are discreet and nearly invisible. Advances in technology have made them smaller, lighter, and more comfortable than ever before."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-restore-hearing-to-normal",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-restore-hearing-to-normal",
    "aria-label": "hearing aids restore hearing to normal permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids restore hearing to normal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids can significantly improve hearing and communication, but they may not restore hearing to normal levels. The goal is to enhance the individual's ability to hear and understand speech in various environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "its-better-to-wait-until-my-hearing-loss-is-severe-before-getting-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#its-better-to-wait-until-my-hearing-loss-is-severe-before-getting-hearing-aids",
    "aria-label": "its better to wait until my hearing loss is severe before getting hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "It's better to wait until my hearing loss is severe before getting hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Addressing hearing loss early is crucial. Delaying the use of hearing aids can lead to social isolation, communication difficulties, and a higher risk of cognitive decline. Early intervention can help maintain an active and engaged lifestyle."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-are-too-expensive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-are-too-expensive",
    "aria-label": "hearing aids are too expensive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids are too expensive."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While hearing aids can be an investment, there are various options at different price points. Some health insurance plans may cover part of the cost, and there are also assistance programs available. The long-term benefits to quality of life often outweigh the initial expense."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-dont-work-well-in-noisy-environments",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-dont-work-well-in-noisy-environments",
    "aria-label": "hearing aids dont work well in noisy environments permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids don't work well in noisy environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Many modern hearing aids have advanced features, such as noise reduction and directional microphones, which help users hear more clearly in challenging environments. Some models can automatically adjust settings to different listening environments."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "start-your-journey-to-better-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start-your-journey-to-better-hearing",
    "aria-label": "start your journey to better hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start your journey to better hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don't let myths and misconceptions keep you from experiencing the joy of clear, vibrant sound. At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we understand the hesitations surrounding hearing aids and are here to debunk the myths that may be holding you back. Our team comprises the top 2% of audiologists in the United States, ready to guide you through the entire process of finding the perfect hearing solution for your unique needs. Take the first step towards a world of better hearing – let us be your trusted partner on this transformative journey."), "\n", React.createElement(LandingPageCta, {
    copy: "Contact a Hearing Specialist",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
